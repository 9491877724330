import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import Calendar from '../components/Bookings/Calendar';
import PageTitleBooking from '../blocks/page-title/PageTitleBooking';

const Bookings = () => {
  document.body.classList.add('page');

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Bokningar | Studio 4 Eriksberg</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Bokning barbershop studio 4 eriksberg"
        />
        <meta
          name="keywords"
          content="barbershop studio 4 eriksberg bästa frisör i göteborg, gbg frisör, eriksberg frisör, frisör eriksberg salong eriksberg, hårklippning eriksberg, barbershop eriksberg, barbershop göteborg, barbershop gbg, hårklippning eriksberg, hårklippning studio 4 eriksberg, hårklippning göteborg, hårklippning gbg, salong studio 4, eriksberg barbershop, göteborg barbershop, studio 4 eriksberg, eriksberg damklipp, eriksberg herklipp, boka frisör eriksberg, eriksberg boka frisör"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleBooking />
        <div id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <Calendar style={{ paddingBottom: '300px' }} />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Bookings;
