import moment from 'moment';
import { database } from '../../../firebase';
import { fetchBookingSlot } from './bookingSlot';

export async function fetchBookingData() {
  const selectedData = [];
  const id = [];
  const id1 = [];
  let employ;
  let isClosedToday;
  let closedDates = [];
  let hoursClose = {};
  let alreadyFetchClosedHoursFromFirebase = false;

  const fetchDatesFromFirebase = async () => {
    if (!alreadyFetchClosedHoursFromFirebase) {
      await database.ref('/dates').once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val().date;
          closedDates.push(childData);
        });
      });
    }
  };

  const fetchClosedHoursFromFirebase = async () => {
    if (!alreadyFetchClosedHoursFromFirebase) {
      await database.ref('/hoursclose').once('value', (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val();
          hoursClose = childData;
        });
      });
      alreadyFetchClosedHoursFromFirebase = true;
    }
  };

  // Fetch data from Firebase
  await database.ref('/anstalld').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      employ = childSnapshot.val().value;
    });
  });

  await database.ref('/closed').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      isClosedToday = childSnapshot.val().value;
    });
  });

  await database.ref('/boka').once('value', (snapshot) => {
    snapshot.forEach((childSnapshot) => {
      const childData = childSnapshot.val().uid;
      id.push(childData);
    });
  });

  if (employ) {
    await database.ref('/boka1').once('value', (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val().uid;
        id1.push(childData);
      });
    });
  }

  await fetchDatesFromFirebase();
  await fetchClosedHoursFromFirebase();

  const getCurrentTime = () => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return formattedDate;
  };

  const getFormattedDate = (ob) => moment(ob.start).format('YYYY-MM-DD');

  const isTimeInRange = (currentTime, startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    const startMinutesTotal = startHours * 60 + startMinutes;
    const endMinutesTotal = endHours * 60 + endMinutes;
    return currentTime >= startMinutesTotal && currentTime <= endMinutesTotal;
  };

  const isOpen = (ob, start, end) =>
    isTimeInRange(ob.start.hour() * 60 + ob.start.minute(), start, end);

  const is40minutesBookingSlot = await fetchBookingSlot();

  const today = moment().startOf('day');
  const endDate = moment().add(1, 'year').startOf('day'); // Generate slots for the next year

  if (isClosedToday) {
    return [];
  }

  for (
    let currentDate = today.clone();
    currentDate.isBefore(endDate);
    currentDate.add(1, 'day')
  ) {
    for (
      let currentMinutes = 10 * 60 + 20;
      currentMinutes < (is40minutesBookingSlot ? 19 * 60 : 18.5 * 60);
      currentMinutes += is40minutesBookingSlot ? 40 : 30
    ) {
      const hour = Math.floor(currentMinutes / 60);
      const minute = currentMinutes % 60;

      const ob = {
        start: currentDate.clone().hour(hour).minute(minute),
        end: currentDate
          .clone()
          .hour(hour)
          .minute(minute)
          .add(is40minutesBookingSlot ? 40 : 30, 'minutes'),
        uid: `${currentDate.format('YYYY-MM-DD')}-${hour}-${minute}`,
        value: '',
        is30minuteSlot: !is40minutesBookingSlot,
        timeSlotReservedAt: getCurrentTime(),
        type: 'boka',
      };

      let ob1;
      if (employ) {
        ob1 = { ...ob, type: 'boka1' };
      }

      if (
        !(
          id.includes(ob.uid) ||
          (ob.start.isoWeekday() === 6 && ob.start.hour() > 17) ||
          ob.start.isBefore(moment()) ||
          (getFormattedDate(ob) === hoursClose.date &&
            isOpen(ob, hoursClose.start, hoursClose.end)) ||
          ob.start.isoWeekday() === 7 || // Sunday
          closedDates.includes(getFormattedDate(ob))
        )
      ) {
        selectedData.push(ob);
      }

      if (employ && ob1) {
        if (
          !(
            id1.includes(ob1.uid) ||
            (ob1.start.isoWeekday() === 6 && ob1.start.hour() > 17) ||
            ob1.start.isBefore(moment()) ||
            ob1.start.isoWeekday() === 7 ||
            closedDates.includes(getFormattedDate(ob1))
          )
        ) {
          selectedData.push(ob1);
        }
      }
    }
  }

  return selectedData;
}
